<template>
  <div class="order-page my-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-10">
          <div class="order-form">
            <h1>Create Your Account</h1>
            <h3>
              Start your FREE 7 Day Trial Now!
            </h3>
            <div class="progress">
              <div class="percent" :style="{ width: `${currentStep / 2 * 100}%` }">
                Step {{ currentStep }} of 2...
              </div>
            </div>
            <hr />
            <p class="long-term">
              No long-term contracts. Cancel your account anytime
            </p>
            <ValidationObserver
              v-slot="{ handleSubmit, invalid, touched }"
              ref="orderForm"
            >
              <form
                @submit.prevent="handleSubmit(handleRegister)"
              >
                <div v-if="currentStep === 1" >
                  <div >
                    <div class="row">
                      <div class="col-md-6">
                        <TextInput
                          v-model="credentials.first_name"
                          placeholder="First name"
                          rules="required"
                          name="first_name"
                        />
                      </div>
                      <div class="col-md-6">
                        <TextInput
                          v-model="credentials.last_name"
                          placeholder="Last name"
                          rules="required"
                          name="last_name"
                        />
                      </div>
                    </div>
                    <TextInput
                      v-model="credentials.email"
                      autocomplete="new-account"
                      placeholder="Enter email"
                      rules="required|email"
                      name="email"
                    />
                    
                    <TextInput
                      v-model="credentials.password"
                      type="password"
                      autocomplete="new-password"
                      placeholder="Enter password"
                      rules="required"
                      name="password"
                    />
                    <b-form-group class="mt-4 mb-1">
                      <b-button
                        variant="primary"
                        class="btn-block"
                        type="button"
                        :disabled="loadingRegister || (invalid && touched)"
                        @click="validateEmail"
                      >
                        <b-spinner v-if="loadingRegister" small />
                        <span v-else>Start Generating More Revenue!</span>
                      </b-button>
                    </b-form-group>
                    <p class="text-center">
                      <small
                        >
                        <i class="uil uil-padlock"></i> 
                        By providing us with your information you are consenting to the collection and use of your information in accordance with our
                        <a
                          href="https://www.customerengine.io/terms-of-service"
                          target="_blank"
                          >Terms Of Service</a
                        >
                        &
                        <a
                          href="https://www.customerengine.io/privacy-policy"
                          target="_blank"
                          >Privacy Policy</a
                        ></small
                      >
                    </p>
                  </div>
                </div>
                <div v-if="currentStep === 2" class="step-2">
                  <div class="row pt-4 pb-4">
                    <div class="col-md-6">
                      <TextInput
                        v-model="credentials.company_name"
                        placeholder="Enter company name"
                        rules="required"
                        name="company_name"
                        label="Company name"
                      />
                    </div>
                    <div class="col-md-6">
                      <PhoneInput
                        v-model="credentials.phone"
                        autocomplete="new-account"
                        placeholder="Phone number"
                        rules="required|phone"
                        name="phone"
                        label="Main Business Phone"
                      />
                    </div>
                    <b-form-group class="col-md-4 offset-md-4 mt-4">
                      <b-button
                        variant="primary"
                        class="btn-block"
                        :disabled="loadingRegister || (invalid && touched)"
                        type="submit"
                      >
                        <b-spinner v-if="loadingRegister" small />
                        <span v-else>Continue</span>
                      </b-button>
                    </b-form-group>
                  </div>
                  
                </div>
              </form>
            </ValidationObserver>
          </div>

          <div class="row mt-3">
            <div class="col-12 text-center">
              <p class="text-muted">
                Already have an account?
                <router-link
                  :to="{ name: 'login' }"
                  class="text-primary font-weight-bold ml-1"
                >
                  <b>Login</b>
                </router-link>
              </p>
            </div>
          </div>

          <div class="about-us">
            <img src="@assets/images/seandaily.jpeg" alt="">
            <div class="content">
              <p>
                Customer Engine is fast to implement and we saw immediate results. I really love the user-friendly platform. 
                They really take care of their customers and It is so easy I feel like I'm cheating at my job. 
                I highly recommend Customers Engine.
              </p>
              <h3>Sean Daily</h3>
            </div>
          </div>
        </div>
        <p class="text-center text-muted mt-5" style="font-size:13px;">
          <strong>Customer Engine</strong> - Copyright {{ year }} - All Rights Reserved - Terms of Service - Privacy Policy
          <br />
          11505 Burnham Drive, Suite 204, Gig Harbor, WA 98332
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'

export default {
  data() {
    return {
      credentials: {
        first_name: '',
        email: '',
        company_name: '',
        plan: '',
        payment_method: '',
      },
      currentStep: 1,
      plan: null,
      loadingRegister: false,
      year: moment().format('Y'),
    }
  },

  computed: {
    whitelabelDomain() {
      return process.env.VUE_APP_WHITELABEL_DOMAIN
    },
  },

  methods: {
    nextStep() {
      this.$refs.orderForm
        .validate()
        .then((isValid) => {
          if (isValid) {
            this.currentStep++
          }
        })
        .catch(() => {})
    },

    handleRegister() {
      this.loadingRegister = true
      this.$store
        .dispatch('auth/registerBusiness', this.credentials)
        .then(() => {
          if (this.$route.query.plan) {
            this.$store
              .dispatch('subscription/getCheckoutLink', this.$route.query.plan)
              .then((href) => {
                location.href = href
                this.loadingRegister = false
              })
              .catch(() => {
                this.loadingRegister = false
                this.$router.push({ name: 'business.subscription'})
              })
          } else {
            this.loadingRegister = false
            this.$router.push({ name: 'business.subscription'})
          }
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.currentStep = 1
            this.$refs.orderForm.setErrors(err.response.data.errors)
          }
          this.loadingRegister = false
        })
    },

    validateEmail() {
      this.loadingRegister = true
      this.$store
        .dispatch('auth/validateEmail', encodeURIComponent(this.credentials.email))
        .then(() => {
          this.loadingRegister = false
          this.nextStep()
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.orderForm.setErrors(err.response.data.errors)
          }
          this.loadingRegister = false
        })
    }
  },
}
</script>

<style lang="scss" >
.order-form {
  box-shadow: 0 0 15px rgb(0 0 0 / 10%);
  max-width: 700px;
  background: #FFF;
  padding: 15px 35px;
  margin: 0 auto;
  h1 {
    font-size: 40px;
  }
  h1, h3 {
    text-align: center;
    color: rgb(46, 66, 112);
    font-weight: 700;
  }
  .long-term {
    text-align: center;
    font-size: 18px;
    line-height: 1.3em;
    color: #2e4270;
  }

  .form-control {
    border: 2px solid #e6e6e6;
    padding: 12px 18px;
    height: 50px;
    &.vti__input {
      border: none;
    }
  }

  .btn {
    font-weight: bold;
    font-size: 25px;
  }

  .progress {
    overflow: hidden;
    height: 35px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border-radius: 20px;
    -webkit-box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
    padding: 10px;
    width: 100%;
    position: relative;
    .percent {
      background-color: rgb(4, 125, 248);
      height: 100%;
      position: absolute;
      top: 0;
      color: #FFF;
      line-height: 35px;
      font-size: 14px;
      font-weight: 600;
      left: 0;
      text-align: center;
    }
  }
}
.order-page {
  .box-upgrade {
    margin-bottom: 30px;
    .btn {
      max-width: 100%;
      padding: 15px 100px;
    }
  }
  .secure-images img {
    width: 300px;
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }

  .about-us {
    width: 600px;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    margin-top: 40px;
    color: rgb(136, 136, 136);
    align-items: center;
    p {
      font-size: 16px;
      font-weight: 600;
    }
    img {
      margin-right: 30px;
      width: 140px;
      border: 1px solid rgba(0,0,0,0.25);
      border-bottom: 2px solid rgba(0,0,0,0.25);
      box-shadow: 0 4px 3px rgb(0 0 0 / 15%), 0 0 2px rgb(0 0 0 / 15%);
      padding: 5px !important;
      border-radius: 200px;
      background: rgba(0,0,0,0.15);
    }
    @media screen and (max-width: 600px) {
      flex-direction: column;
      img {
        margin-bottom: 30px;
      }
    }
  }
}
</style>
